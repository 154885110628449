import { ImageProps } from 'next/image';
import { PrismicImage } from 'utils/prismic/types';
import theme from 'setup/theme';

const pxToNumber = (value: string): string => value.replace('px', '');

export const getImageProps = (
  image: PrismicImage,
  isMobile = false,
): ImageProps => {
  const { url, alt, dimensions } = image;
  const { width = 0, height = 0 } = dimensions || {};

  const [phone, , desktop] = theme.breakpoints;

  if (!url) {
    return {
      src: '',
    };
  }

  const src = new URL(url);

  src.searchParams.append('fit', 'max');
  src.searchParams.append(
    'max-w',
    isMobile ? pxToNumber(phone) : pxToNumber(desktop),
  );

  return {
    src: src.toString(),
    alt: alt || undefined,
    height,
    width,
    layout: 'responsive',
    objectFit: 'contain',
    objectPosition: 'center',
  };
};
