import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Inner from 'components/styles/Inner';
import Image from 'next/image';
import media from 'setup/MediaQueries';
import { Box } from 'theme-ui';
import {
  StyledBackgroundWrapperProps,
  StyledFitToContentSectionProps,
} from './types';

export const StyledSectionWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 100%;
`;

export const StyledSection = styled(Box)`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledFitToContentSection = styled(
  Box,
)<StyledFitToContentSectionProps>`
  position: relative;

  ${({ theme, withOverlay }) =>
    withOverlay &&
    css`
      color: ${theme.colors.white};
    `}
`;

export const StyledFitToContentBackgroundWrapper = styled(
  Box,
)<StyledBackgroundWrapperProps>`
  position: absolute;
  inset: 0;

  display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};

  @media ${media.phone} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  }
`;

export const StyledBackgroundWrapper = styled(
  Box,
)<StyledBackgroundWrapperProps>`
  position: relative;

  grid-column: 1 / -1;
  grid-row: 1 / -1;

  max-width: 100%;
  width: 100%;

  display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};

  @media ${media.phone} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  }
`;

export const StyledInner = styled(Inner)`
  height: 100%;
  background-color: red;
`;

export const StyledBackground = styled(Image)``;

export const StyledBackgroundOverlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(242, 163, 59, 0.89) 0%,
    rgba(38, 41, 69, 0.9) 100%
  );
`;
