import React from 'react';
import { SectionBackground, SectionSpacing } from '../../enums';
import {
  StyledBackground,
  StyledBackgroundOverlay,
  StyledBackgroundWrapper,
  StyledFitToContentBackgroundWrapper,
  StyledFitToContentSection,
  StyledSection,
  StyledSectionWrapper,
} from './styles';
import { FullWidthImageBackgroundSectionProps } from './types';
import { getImageProps } from './utils';

const FullWidthImageBackgroundSection: React.FC<
  FullWidthImageBackgroundSectionProps
> = ({
  background = SectionBackground.Light,
  topSpacing = SectionSpacing.VerySmall,
  bottomSpacing = SectionSpacing.VerySmall,
  children,
  as = 'section',
  isWide,
  id,
  sectionType,
  backgroundImage,
  backgroundMobileImage,
  withOverlay,
  fitHeightTo = 'section',
  ...props
}) => {
  if (fitHeightTo === 'content') {
    return (
      <StyledFitToContentSection
        {...{ ...props, withOverlay }}
        pt={topSpacing}
        pb={bottomSpacing}
        data-section-type={sectionType}
        as={as as unknown as React.ElementType<unknown>}
      >
        <StyledFitToContentBackgroundWrapper>
          <StyledBackground
            {...getImageProps(backgroundImage)}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
          {withOverlay && <StyledBackgroundOverlay />}
        </StyledFitToContentBackgroundWrapper>
        <StyledFitToContentBackgroundWrapper isMobile>
          <StyledBackground
            {...getImageProps(
              !backgroundMobileImage ||
                Object.keys(backgroundMobileImage).length === 0
                ? backgroundImage
                : backgroundMobileImage,
              true,
            )}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
          {withOverlay && <StyledBackgroundOverlay />}
        </StyledFitToContentBackgroundWrapper>
        {children}
      </StyledFitToContentSection>
    );
  }

  return (
    <StyledSectionWrapper>
      <StyledBackgroundWrapper>
        <StyledBackground {...getImageProps(backgroundImage)} />
        {withOverlay && <StyledBackgroundOverlay />}
      </StyledBackgroundWrapper>

      <StyledBackgroundWrapper isMobile>
        <StyledBackground
          {...getImageProps(
            !backgroundMobileImage ||
              Object.keys(backgroundMobileImage).length === 0
              ? backgroundImage
              : backgroundMobileImage,
            true,
          )}
        />
        {withOverlay && <StyledBackgroundOverlay />}
      </StyledBackgroundWrapper>

      <StyledSection
        {...props}
        pt={topSpacing}
        pb={bottomSpacing}
        data-section-type={sectionType}
        as={as as unknown as React.ElementType<unknown>}
      >
        {children}
      </StyledSection>
    </StyledSectionWrapper>
  );
};
export default FullWidthImageBackgroundSection;
