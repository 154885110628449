import React, { useMemo } from 'react';

import BlankAnchor from 'components/atoms/BlankAnchor';
import Image from 'next/image';
import useIsDesktopDevice from 'hooks/useIsDesktopDevice';
import Inner from '../Inner';
import { SectionBackground, SectionSpacing } from './enums';
import { StyledSection } from './styles';
import { SectionProps, RenderImageProps } from './types';
import FullWidthImageBackgroundSection from './partials/FullWidthImageBackgroundSection';
import { hasFullWidthBackgroundImage } from './utils';

const Section: React.FC<SectionProps> = ({
  background = SectionBackground.Light,
  topSpacing = SectionSpacing.VerySmall,
  bottomSpacing = SectionSpacing.VerySmall,
  children,
  as = 'section',
  isWide,
  id,
  sectionType,
  backgroundImage,
  backgroundMobileImage,
  fitHeightTo,
  withOverlay,
  ...props
}) => {
  const content = useMemo(
    () => (
      <React.Fragment>
        {id && <BlankAnchor {...{ id }} />}
        <Inner {...{ isWide }}>
          <RenderBackgroundImage {...{ background }} />
          {children}
        </Inner>
      </React.Fragment>
    ),
    [background, children, id, isWide],
  );

  if (hasFullWidthBackgroundImage(background, backgroundImage)) {
    return (
      <FullWidthImageBackgroundSection
        {...{
          background,
          topSpacing,
          bottomSpacing,
          children,
          as,
          isWide,
          id,
          sectionType,
          backgroundImage,
          backgroundMobileImage,
          fitHeightTo,
          withOverlay,
          ...props,
        }}
      >
        {content}
      </FullWidthImageBackgroundSection>
    );
  }

  return (
    <StyledSection
      {...{ background, ...props }}
      pt={topSpacing}
      pb={bottomSpacing}
      data-section-type={sectionType}
      as={as as unknown as React.ElementType<unknown>}
    >
      {content}
    </StyledSection>
  );
};

const RenderBackgroundImage: React.FC<RenderImageProps> = ({ background }) => {
  const isDesktop = useIsDesktopDevice();

  switch (background) {
    case SectionBackground.HeroBanner: {
      if (!isDesktop) {
        return null;
      }

      return (
        <Image
          src={`/assets/images/${background}.jpg`}
          layout="fill"
          objectFit="cover"
          objectPosition={`${isDesktop ? 'center' : '65%'} center`}
          priority
          quality={100}
        />
      );
    }
    case SectionBackground.CtaBanner: {
      return (
        <Image
          src={`/assets/images/${background}.jpg`}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          quality={100}
        />
      );
    }
    default:
      return null;
  }
};

export default Section;
